@import "../../assets/scss/variables.scss";

.Footer{
  position: fixed;
  bottom:0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px 15px 30px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2) inset;
  background-color: #ffffff;
  max-width: 576px;
  &:after{
    position: absolute;
    content: '';
    width: 100%;
    height: 15px;
    background:  rgba(255, 255, 255, .5);
    left: 0;
    bottom: 100%;
    border-radius: 50% 50% 47% 53% / 100% 100% 0% 0%;
  }
  .pic{
      position: relative;
      cursor: pointer;
      &.active {
          &::after{
              content: "";
              display: block;
              margin: auto;
              position: absolute;
              width: 5px;
              height: 5px;
              background: $bluePrimary;
              border-radius: 50%;
              left: 0;
              right: 0;
          }
          svg g path{
              stroke: $bluePrimary;
          }
      } 
      .bulle{
          position: absolute;
          top: 0;
          right: -7px;
          font-family: ff(ttr);
          width: auto;
          height: 13px;
          padding: 0 4px;
          border-radius: 6.5px;
          background-color: #d0021b;
          color: #FFF;
          font-size: .5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 0;
          line-height: 0;
      }
  }
}