.HomePart{
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  &:first-child{
    padding-top: 15px;
  }
  .hp-title{
    color: #00375b;
    font-size: 1.5rem;
    font-weight: 600;
    text-shadow: 0 2px 4px rgba(255,255,255,.5);
    margin-bottom: .5rem;
    line-height: 1.2;
  }
  .hp-content{
    align-self: stretch;
  }
  &:not(.simple){
    margin-bottom: 30px;
  }
}