.EventValidate{
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 0 -15px 18px 0 rgba(0,0,0,.15);
  margin-left: 30px;
  background-color: #fff;
  bottom: 57px;
  padding: 12px 32px;
  position: fixed;
  z-index: 1000;
  max-width: 516px;
  transition: opacity .3s ease, transform .3s ease;
  opacity: 0;
  pointer-events: none;
  transform: translateY(50vh);
  &.active{
    opacity: 1;
    pointer-events: initial;
    transform: translateY(0);
  }
  input::-webkit-outer-spin-button,input::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0;}
  input[type=number] {-moz-appearance: textfield;}
  .ev-handler{
    background-color: #00375b;
    width: 135px;
    height: 5px;
    border-radius: 5px;
    margin: 0 auto 40px auto;
  }
  .separator{
    background: #bebebe;
    height: 2px;
    margin: 18px 0;
  }
  .ev-title{
    color: #00375b;
    font-size: 30px;
    font-weight: 900;
    margin-bottom: 24px;
    text-align: center;
  }
  .ev-more{
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .ev-option{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ev-opt-title{
      align-self: stretch;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .ev-label{
      color: #00375b;
      font-size: 18px;
      font-weight: 900;
      line-height: normal;
    }
    .ev-rest{
      font-size: 12px;
      color: #00375b;
      font-weight: 500;
      .ev-data{
        color: #1b7a9e
      }
    }
    .ev-friend-count{
      padding: 4px 10px;
      border-radius: 5px;
      box-shadow: inset 0 1px 4px 0 rgba(0,0,0,.2);
      background-color: #fff;
      width: 63px;
      border: none;
      font-size: 24px;
      color: #00375b;
      font-weight: 900;
      margin-left: 16px;
      margin-right: 30px;
      text-align: right;
    }
    &.column{
      flex-direction: column;
      align-items: flex-start;
      .ev-label{
        margin-bottom: 16px;
      }
      input{
        width: 100%;
        border: none;
        border-bottom: 2px solid rgba(163,163,163,.18);
        padding-left: 0 16px;
        padding-bottom: 10px;
        margin-bottom: 24px;
        background: transparent;
        outline: none;
        &::placeholder{
          color: #bebebe;
          font-weight: 500;
        }
      }
    }
    .ev-increase{
      display: flex;
      justify-content: center;
      border: none;
      span{
        color: #1b7a9e;
        box-shadow: 0 0 0 2px #1b7a9e inset;
        width: 50px;
        height: 40px;
        font-size: 27px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
        &.plus{
          margin-left: -2px;
          border-top-right-radius: 24px;
          border-bottom-right-radius: 24px;
        }
        &.moin{
          border-top-left-radius: 24px;
          border-bottom-left-radius: 24px;
        }
      }
    }
  }
  .ev-last-options{
    display: grid;
    gap: 2em;
    grid-template-columns: 1fr 1fr;
  }
}