@import "../../assets/scss/variables.scss";

.Chooser{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  &:not(.full){
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    .choice{
      margin-right: 1rem;
      margin-bottom: 1rem;
    }
  }
  .choice {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    padding: 0.375rem 0.75rem;
    transition: 0.25s ease;
    border-radius: 24px;
    border: 2px solid #1b7a9e;
    color: #1b7a9e;
    font-weight: 500;
    text-align: center;
    &.active, &:hover{
      border: 2px solid #1b7a9e;
      color: #fff;
    }
    &:hover{
      background-color: rgba($color:  #1b7a9e, $alpha: .8);
    }
    &.active{
      transition: 0.05s ease;
      background-color:  #1b7a9e;
    }
    &.spaced{
      margin-bottom: 1rem;
    }
  }
  &.big{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1em;
    .choice{
      padding: 11px;
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}