main.container-fluid {
  padding: 30px;
  min-height: 100vh;
  position: relative;
  font-family: ff(ttr);
  @media (max-width: 385px) {
    padding: 24px;
  }
}
.ttr,
.sttr {
  color: #fff;
  font-weight: 600;
  text-shadow: 0 2px 4px rgba(255, 255, 255, 0.5);
}
.title {
  margin-top: 2.5rem;
  position: relative;
  z-index: 2;
  @media (max-width: 385px) {
    margin-top: 2rem;
  }
  @media (max-width: 320px) {
    margin-top: 1.25rem;
  }
}
.para {
  color: #fff;
  font-size: 0.75em;
  font-family: ff(ttr);
}
footer,
header,
section {
  position: relative;
  z-index: 2;
}
.hidden {
  display: none;
}

.backbtn {
  margin-left: -16px;
}
.blocL {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e9e9e9;
  height: 40px;
  width: auto;
  border-radius: 20px;
  margin-right: 11px;
  margin-left: 16px;
  padding: 10px;
  position: relative;
  .img {
    position: absolute;
    left: -40px;
    width: 100px;
  }
  .infos {
    color: #2491a0;
    font-size: 12px;
    font-weight: 900;
    margin-bottom: 0;
    margin-left: 32px;
    font-family: ff(ttr);
    span {
      font-size: 16px;
    }
  }
} // end blocL

body {
  &#desktop {
    max-width: 100%;
    .desk {
      left: 361px;
      position: relative;
      max-width: calc(100% - 361px);
      padding-right: 32px;
      .mp,
      .message {
        .container-fluid {
          margin: 0;
          position: relative;
        }
      }
      .message {
        max-width: 431px;
      }
      .mp {
        .footer {
          padding: 24px 32px;
          margin-top: 100px;
          position: relative;
          max-width: 100%;
          background-color: #f4f4f4;
          input {
            width: 376px;
          }
        }
        .consommer article {
          position: relative;
          width: 100%;
        }
      }
    }
    #lancedefi,
    #exceptionnel {
      background-image: linear-gradient(to bottom, #3ccca7, #1b7a9e);
    }
    #exceptionnel {
      .texte {
        .info {
          width: 400px;
          margin: 0 auto;
          margin-bottom: 48px;
        }
        .bottom {
          text-align: center;
          .btn {
            width: auto;
            padding: 13px 59px;
          }
        }
      }
    }
    .home {
      width: calc(100% - 361px);
      left: 361px;
      .container-fluid {
        max-width: 431px;
        min-width: 431px;
        overflow: hidden;
      }
      .header {
        padding-right: 0;
        .blocL {
          margin-right: 0;
        }
      }
    }
    .popup {
      right: 30px;
      max-width: 431px;
    }
    #sidebar {
      width: 361px;
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      z-index: 999;
      background: #eaeaea;
      color: #fff;
      transition: all 0.3s;
      &.active {
        margin-left: -250px;
      }
      .sidebar-header {
        padding: 16px;
        background: #eaeaea;
        text-align: right;
        width: 100%;
      }
      ul {
        p {
          color: #fff;
          padding: 10px;
        }
        li {
          border-top: 1px solid #dfdfdf;
          padding: 16px;
          display: block;
          position: relative;
          a {
            color: $blueNight;
            font-size: 14px;
            font-weight: 500;
            font-family: ff(ttr);
            text-decoration: none;
            position: relative;
            left: 50%;
            span {
              padding-left: 16px;
            }
            .pic {
              display: inline-block;
              position: relative;
              .bulle {
                position: absolute;
                top: 0;
                right: -7px;
                width: auto;
                height: 13px;
                padding: 0 4px;
                border-radius: 6.5px;
                background-color: #d0021b;
                color: #fff;
                font-size: 0.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 0;
                line-height: 0;
              }
            }
            svg {
              position: relative;
              width: 32px;
              height: 32px;
              g {
                path {
                  stroke: $blueNight;
                }
              }
            }
          }
          &:hover {
            color: $bluePrimary;
            background: #fff;
            a {
              color: $bluePrimary;
              svg {
                g {
                  path {
                    box-shadow: 0 2px 4px 0 rgba(27, 122, 158, 0.5);
                    stroke: $bluePrimary;
                  }
                }
              }
            }
          }
          &:last-child {
            border-bottom: 1px solid #dfdfdf;
          }
          &.active {
            background: #ffffff;
            border-right: 5px solid $bluePrimary;
            a {
              color: $bluePrimary;
              svg {
                g {
                  path {
                    stroke: $bluePrimary;
                  }
                }
              }
            }
            .pic::after {
              content: "";
              display: block;
              margin: auto;
              position: absolute;
              width: 6px;
              height: 6px;
              background: #1b7a9e;
              border-radius: 100%;
              left: 0;
              right: 0;
              box-shadow: 0 2px 4px 0 rgba(27, 122, 158, 0.5);
            }
          }
        }
      }
      .avatar {
        position: relative;
        .bulle {
          position: absolute;
          top: 0;
          right: 0;
          width: auto;
          height: 13px;
          padding: 0 4px;
          font-family: ff(ttr);
          border-radius: 6.5px;
          background-color: #d0021b;
          color: #fff;
          font-size: 0.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 0;
          line-height: 0;
        }
      }
    }
  }
}
