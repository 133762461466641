.Plaque{
  display: flex;
  .p-logo{
    width: 55px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 1em;
    &:before{
      content: "";
      display: block;
      padding-top: 100%;
    }
  }
  .p-text{
    flex: 1
  }
}