.Stepper{
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  >div, >fieldset{
    min-width: 0;
    margin: 0;
    border: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    transform: translate3d(100%, 0, 0);
    opacity: .8;
    transition: opacity .8s cubic-bezier(.5,5,.6,5), transform .8s cubic-bezier(.94,.08,.72,1.18);
    z-index: 3;
    &.step-active{
      z-index: 2;
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity .8s ease, transform .8s cubic-bezier(.94,.08,.72,1.18);
    }
    &.step-hidden{
      z-index: 1;
      transform: translate3d(0, 0, 0) scale(.8);
      transition: opacity .8s ease, transform .8s ease;
      opacity: 0;
    }
  }
  &:not(.simple){
    >div, >fieldset{
      padding: 32px;
    }
  }
  >.step-control{
    z-index: 5;
    position: absolute;
    bottom: 0;
    height: 48px;
    width: 48px;
    background-size: cover;
    transition: background-image .3s ease, transform .3s ease;
    margin: 1.5rem 2rem;
    transform: scale(0);
    &.active{
      transform: scale(1);
    }
    &.step-prev{
      background-image: url(../../assets/images/back-off.svg);
      left: 0;
      &:hover{
        background-image: url(../../assets/images/back-on.svg);
      }
    }
    &.step-next{
      right: 0;
      background-image: url(../../assets/images/next-off.svg);
      &:hover{
        background-image: url(../../assets/images/next-on.svg);
      }
    }
  }
}