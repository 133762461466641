.Header {
  position: fixed;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  top: 0;
  max-width: 576px;
  background: #fff;
  z-index: 5;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2) inset;
  &:after{
    position: absolute;
    content: '';
    width: 100%;
    height: 15px;
    background:  rgba(255, 255, 255, .5);
    left: 0;
    border-radius: 100% 0% 50% 50% / 0% 0% 100% 100% ;
    top: 100%;
  }
  .h-cherriz{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    background: #e9e9e9;
    height: 40px;
    margin-left: 24px;
    border-radius: 20px;
    padding-left: 50px;
    padding-right: 15px;
    color: #2491a0;
    font-size: 12px;
    font-weight: 900;
    margin-right: 20px;
    &::before{
      content: '';
      position: absolute;
      left: -30px;
      top: -30px;
      width: 100px;
      height: 100px;
      background-size: contain;
      pointer-events: none;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url(../../assets/images/coach/logo-fremium-copy.svg);
    }
    span{
      font-size: 16px;
      margin-right: .5em;
    }
  }
  .avatar {
    position: relative;
    width: 40px;
    height: 40px;
    .bulle {
      position: absolute;
      top: 0;
      right: 0;
      width: auto;
      height: 13px;
      padding: 0 4px;
      font-family: ff(ttr);
      border-radius: 6.5px;
      background-color: #d0021b;
      color: #fff;
      font-size: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
      line-height: 0;
    }
  }
}