.Login{
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000), url(../../assets/images/ssbg.jpg);
  background-size: cover;
  min-height: 100vh;
  padding: 0 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 1em;
  >*{
    position: relative;
  }
  .l-connex-button{
    padding: 10px;
    cursor: pointer;
    padding-top: 1.25rem;
    text-transform: uppercase;
    color: #00375b;
    font-weight: 500;
    text-align: right;
  }
  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../../assets/images/bg.png);
    background-size: cover;
    opacity: .5;
    
  }
  .l-separator{
    height: 2px;
    background: #eee;
    margin: 0 4em;
    opacity: .5;
    border-radius: 100%;
  }
  .l-footer{
    text-align: center;
    font-weight: 500;
    color: #fff;
    font-size: .75em;
    padding-bottom: 1.25rem;
  }
  .l-buttons{
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    &:not(.simple){
      margin-bottom: 1.8rem;
    }
    input{
      box-shadow: 0 2px 4px rgba(255,255,255,.5);
      margin-bottom: 2em;
      padding: .5em 1em;
      font-size: 1.2em;
      border-radius: 5px;
      border: none;
      outline: none;
    }
  }
}