.CherieButton{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  width: 100%;
  font-weight: bold;
  font-size: 1.125rem;
  padding: 10px;
  background-color: #1b7a9e;
  border: none;
  text-align: center;
  user-select: none;
  color: #fff;
  cursor: pointer;
  text-decoration: none !important;
  &:not(.simple){
    margin-top: 20px;
  }
  img{
    margin-right: 1em;
    height: .9em;
  }
  &.inverse{
    background: #dadada;
    color: #1b7a9e;
  }
  &.small{
    font-size: 12px;
    padding: 6px;
    color: #1b7a9e;
  }
  span{
    text-decoration-color: transparent;
  }
  &.inactive{
    opacity: .7;
    filter: grayscale(.5);
    pointer-events: none;
    user-select: none;
  }
}