.CurtailOne{
  color: #fff;
  background-size: 100% 100% !important;
  background-position: center;
  background-image: url(../../../assets/images/images/greenblueback.png) !important;
  padding: 35px;
  .co-logo{
    width: 100px;
    text-shadow: 0 2px 4px rgba(255,255,255,.5);
    margin-top: 1rem;
  }
  .co-title{
    font-size: 56px;
    line-height: 1.04;
    letter-spacing: 1.33px;
    font-family: "Roboto",sans-serif;
    font-weight: 900;
    margin-top: 24px;
    margin-bottom: 48px;
  }
  .co-item{
    display: flex;
    margin-bottom: 55px;
    .co-item-logo{
      margin-right: 1rem;
      width: 60px;
    }
  }
  .button-parent{
    padding: 2em;
  }
  &:before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url(../../../assets/images/images/voile.png);
    background-size: cover;
    background-position: center;
    z-index: 0;
  }
  &>*{
    position: relative;
    z-index: 1;
  }
}