.BigCheckbox{
  display: flex;
  align-items: center;
  &:not(.simple){
    margin-bottom: .8em;
  }
  .label{
    flex: 1;
    color: #00375b;
    font-size: 1.25rem;
    font-weight: 800;
  }
  .checkbox{
    margin: 2px 2px 2px 0;
    border-radius: 24px;
    box-shadow: 0 0 0 2px #00375b;
    background-color: #e9e9e9;
    width: 100px;
    height: 38px;
    position: relative;
    cursor: pointer;
    transition: background-color .3s .1s ease;
    @media (max-width: 345px) {
      width: 84px;
      height: 34px;
    }
    &:before {
      content: "Non";
      width: 62px;
      position: absolute;
      top: 0;
      left: 38px;
      height: 100%;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #00375b;
      font-family: ff(cond);
      font-size: 1rem;
      font-weight: 600;
      transition: all .3s ease, color .1s;
      z-index: 1;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      display: block;
      width: 38px;
      height: 38px;
      background-color: #d5dade;
      box-shadow: 0 0 0 2px #00375b;
      border-radius: 50%;
      transition: all 0.3s ease;
      z-index: 2;
      left: 0;
    }
    &.checked{
      background-color: #1b7a9e;
      &:before{
        content: "Oui";
        left: 0;
        color: #e9e9e9;
      }
      &:after{
        left: 62px;
      }
    }
  }
}