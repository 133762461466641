.PinDetail{
  display: flex;
  .pd-icon{
    width: 30px;
    height: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .pd-text{
    margin-left: 1em;
    color: #00375b;
    font-size: 12px;
    font-weight: 500;
  }
}