.LogoBox{
  display: flex;
  flex-direction: column;
  align-items: center;
  .logo{
    width: auto;
    border-radius: 11.1px;
    box-shadow: 0 14px 17px 0 rgba(0,0,0,.15);
    background-color: #fff;
    padding: 7px;
    margin-bottom: .5rem;
    width: 72px;
    height: 72px;
    background-position: center;
    background-size: 81%;
    background-repeat: no-repeat;
  }
  .label{
    text-align: center;
    font-size: .75rem;
    color: #00375b;
    font-weight: 500;
    margin-bottom: .5rem;
    width: 85px;
    line-height: normal;
  }
  &.checked{
    .label{
      color: #1b7a9e;
    }
    .logo{
      box-shadow: 0 0 0 4px #1b7a9e;
    }
  }

}