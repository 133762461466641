.People{
  position: relative;
  display: flex;
  align-items: center;
  .images{
    display: flex;
    margin-right: 1.5em;
    padding: 2px;
    .image{
      width: 25px;
      height: 25px;
      border-radius: 100%;
      margin-left: -7px;
      box-shadow: 0 0 0 2px #fff;
      background-image: url(../../assets/images/home/photo.png);
      background-position: center;
      background-size: cover;
      &:first-child{
        margin-left: 0;
      }
    }
  }
  >span{
    margin-bottom: 0;
    font-size: .75rem;
    font-weight: bold;
    color: #fff;
  }
}