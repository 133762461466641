.InfoBack{
  position: relative;
  border-radius: 15px;
  background: linear-gradient(to right, #3ccca7 0%, #1b7a9e 100%);
  color: #fff;
  overflow: hidden;
  display: flex;
  min-height: 100px;
  .ib-back{
    position: relative;
    margin: 0 -20px;
    width: 20%;
    background-size: auto 100%;
    background-position: 100% 100%;
    margin-bottom: 10px;
    background-repeat: no-repeat;
    pointer-events: none;
    &:before{
      content: "";
      display: block;
      padding-top: 100%;
    }
  }
  .ib-texts{
    flex: 1;
    padding: 16px 30px;
     .ib-title{
      font-size: 2rem;
      font-weight: 600;
      text-shadow: 0 2px 4px rgba(255,255,255,.5);
      line-height: 1.2;
      margin-bottom: .5rem;
    }
    .ib-detail-parent{
      position: relative;
      display: flex;
      .ib-detail{
        font-size: 1.1rem;
      }
      .ib-back{
        position: relative;
        margin: 0;
        min-width: 6em;
        background-size: 80% auto;
        background-position: top left;
        background-repeat: no-repeat;
        pointer-events: none;
        margin-bottom: 0px;
      }
    }
  }
}