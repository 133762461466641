.SimpleSignup{
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000), url(../../../assets/images/ssbg.jpg);
  background-size: cover;
  min-height: 100vh;
  padding: 0 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute!important;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  z-index: 5;
  transform: translateY(500px);
  opacity: 0;
  transition: transform .3s ease, opacity .3s ease;
  pointer-events: none;
  &.active{
    transform: translateX(0);
    opacity: 1;
    pointer-events: initial;
  }
  >*{
    position: relative;
  }
  .ss-connex-button{
    padding: 10px;
    cursor: pointer;
    padding-top: 1.25rem;
    text-transform: uppercase;
    color: #00375b;
    font-weight: 500;
    text-align: right;
  }
  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../../../assets/images/bg.png);
    background-size: cover;
    opacity: .5;
    
  }
  .ss-separator{
    height: 2px;
    background: #eee;
    margin: 4em;
    opacity: .5;
    border-radius: 100%;
  }
  .ss-title{
    position: relative;
    z-index: 2;
    font-size: 50px;
    color: #fff;
    font-weight: 600;
    text-shadow: 0 2px 4px rgba(255,255,255,.5);
    line-height: 1.2;
    padding-left: 2rem;
  }
  .ss-buttons{
    margin-bottom: 1.8rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    input{
      box-shadow: 0 2px 4px rgba(255,255,255,.5);
      margin-bottom: 2em;
      padding: .5em 1em;
      font-size: 1.2em;
      border-radius: 5px;
      border: none;
      outline: none;
      &.error{
        background-color: rgb(250, 182, 182);
      }
    }
  }
}