.GalleryPart{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  .gp-image{
    border-radius: .5em;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0 0 2px #888;
    background-image: url(../../assets/images/home/photo.png);
    &:first-child{
      grid-row: span 2;
      grid-column: span 2;
    }
  }
}