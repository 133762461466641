
.MainSignup{
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000), url(../../../assets/images/ssbg.jpg);
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden!important;
  >*{
    position: relative;
  }
  .ms-connex-button{
    padding: 10px;
    cursor: pointer;
    text-transform: uppercase;
    color: #00375b;
    font-weight: 500;
    text-align: right;
    padding-top: 0;
  }
  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background-image: url(../../../assets/images/bg.png);
    background-size: cover;
    opacity: .5;
  }
  .ms-title{
    margin-bottom: 2rem;
    position: relative;
    z-index: 2;
    font-size: 50px;
    margin-top: -1.3em;
    color: #fff;
    font-weight: 600;
    text-shadow: 0 2px 4px rgba(255,255,255,.5);
    line-height: 1.2;
    padding-left: 2rem;
    pointer-events: none;
  }
  .ms-footer{
    text-align: center;
    font-weight: 500;
    color: #fff;
    font-size: .75em;
    margin-bottom: 1em;
  }
  .ms-buttons{
    margin-bottom: 1.8rem;
    padding: 0 2rem;
  }
}