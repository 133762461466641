.EventBox {
  padding: 16px;
  position: relative;
  border-radius: 1.5em;
  background-position: center;
  background-size: cover;
  width: 330px;
  height: 483px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-image: url(../../assets/images/home/bg.png);
  overflow: hidden;
  &:before {
    content: "";
    opacity: 0.8;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  &>*{
    position: relative
  }
  .le-date {
    position: absolute;
    left: 16px;
    top: 16px;
    background: rgba(1, 1, 1, 0.75);
    padding: 8px;
    border-radius: 5px;
    margin-bottom: 0;
    font-size: 0.75rem;
    font-weight: bold;
    color: #fff;
  }
  .le-title {
    position: relative;
    margin-top: 15px;
    color: #fff;
    font-weight: 600;
    text-shadow: 0 2px 4px rgba(255, 255, 255, 0.5);
    margin-bottom: 0.5rem;
    line-height: 1.2;
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
  }
  .le-detail {
    font-size: 1rem;
    font-weight: 300;
    color: #fff;
    font-family: "Roboto", sans-serif;
    margin-bottom: 1rem;
  }
}
