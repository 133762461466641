.Ecology{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-image: url(../../assets/images/home/illustration.svg);
  background-size: auto 85%;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  .ec-title{
    color: #00375b;
    font-size: 2.5rem;
    font-weight: 900;
    font-family: "Roboto",sans-serif;
    line-height: 2.75rem;
    text-shadow: 0 2px 4px rgba(255,255,255,.5);
    margin-bottom: .5rem;
  }
  .ec-detail{
    color: #8c8c8c;
    font-family: "Roboto",sans-serif;
    font-size: 1rem;
    line-height: 1.15rem;
    margin-bottom: 5px;
    font-weight: 500;
  }
  .ec-link{
    font-weight: 500;
    color: #1b7a9e;
    font-size: 1rem;
    margin-bottom: 1rem;
    font-family: "Roboto",sans-serif;
    display: block;
    text-decoration: none;
    background-image: url(../../assets/images/defi/defi.svg);
    background-position: 100% 50%;
    background-size: auto 150%;
    background-repeat: no-repeat;
    padding-right: 2em;
    &:hover{
      text-decoration: underline;
      cursor: pointer;
    }
  }
}