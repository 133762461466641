.Coaches{
  border-radius: 15px;
  padding: 25px 15px;
  background: #444;
  background-image: url(../../assets/images/images/girltransparent.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  .cc-title{
    font-size: 1.5rem;
    color: #fff;
    font-weight: bold;
    margin-bottom: .5rem;
    line-height: 1.2;
  }
  .cc-details{
    color: #fff;
    font-weight: 500;
    font-size: .75em;
    margin-bottom: 1rem;
  }
  .cc-coaches{
    display: flex;
    justify-content: space-between;
    .cc-profile{
      width: 23%;
      position: relative;
      min-height: 10px;
      background-size: cover;
      background-position: center;
      border-radius: 7px;
      overflow: hidden;
      &:before{
        content: "";
        display: block;
        padding-top: 100%;
      }
      .cc-container{
        position: absolute;
        width: 100%;
        height: 100%;
        top:0;
        left:0;
        background-image: linear-gradient(to right, #3ccca7 0%, #1b7a9e 80%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .cc-discover-text{
          text-align: center;
          position: relative;
          color: #fff;
          font-size: .75rem;
          font-weight: bold;
          padding: 0 7px;
        }
        .cc-discover-icon{
          width: 15%;
          min-height: 20px;
          margin-bottom: 10%;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url(../../assets/images/icons/arrow.svg);
          &:before{
            content: "";
            display: block;
            padding-top: 100%;
          }
        }
      }
    }
  }
}