@import "./assets/scss/variables.scss";
@import "./assets/scss/common.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
  #root {
    max-width: 576px;
    margin: auto !important;
    font-family: "Roboto", sans-serif;
    .App {
      position: relative;
      overflow: hidden;
      > main {
        min-height: 100vh;
        position: relative;
        &.with-header {
          padding-top: 80px;
        }
        &.with-footer {
          padding-bottom: 78px;
        }
      }
    }
  }
}
