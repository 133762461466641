.LoginButton{
  width: 100%;
  color: #fff;
  padding: 10px;
  text-align: left;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  font-weight: 500;
  display: flex;
  border-radius: .25rem;
  cursor: pointer;
  user-select: none;
  &:not(.simple){
    margin-bottom: 1.3rem;
  }
  .icon{
    width: 70px;
    height: 25px;
    background-size: auto 80%;
    background-position: center;
    background-repeat: no-repeat;
    border-right: 1px solid #fff;
  }
  >span{
    text-align: center;
    flex: 1;
  }
  &.facebook{
    background-color: #183a81;
  }
  &.linkedin {
    background-color: #2c74e9;
  }
  &.google {
    background-color: #2476fa;
  }
  &.apple {
    background-color: #fff;
    color: #333;
    .icon{
      border-color: #333;
    }
  }
}