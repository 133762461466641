.EventCard {
  border-radius: 1.5em;
  box-shadow: 0 15px 18px 0 rgba(0,0,0,.15);
  overflow: hidden;
  .ec-image {
    background-position: center;
    background-size: cover;
    background-image: url(../../assets/images/coach/media.png);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 520px;
    padding: 16px;
    padding-top: 0;
  }
  .ec-info {
    padding: 16px;
    background-color: #fff;
    border-radius: 15px;
    .ec-date {
      font-size: 12px;
      text-transform: uppercase;
      color: #1b7a9e;
      font-weight: 500;
      margin-bottom: 8px;
      text-shadow: 0 2px 4px rgba(255, 255, 255, 0.5);
      line-height: 1.2;
    }
    .ec-title {
      font-size: 24px;
      font-weight: bold;
      color: #00375b;
      text-shadow: 0 2px 4px rgba(255, 255, 255, 0.5);
      margin-bottom: 0.5rem;
    }
    .ec-detail {
      color: #bebebe;
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 1rem;
    }
    .ec-more{
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
  }
}
