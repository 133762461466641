.PhotoBox{
  position: relative;
  min-height: 5em;
  background: #eaeaea;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16.8674% auto;
  border-radius: 10px;
  background-image: url(../../assets/images/icones-ajouter-photo.svg);
  &.filled{
    background-size: cover;
  }
  &:before{
    content: '';
    border-radius: 10px;
    box-shadow: 0 0 0 4px #5ca5c0 inset;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1;
  }
  .cam-parent{
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    border-radius: 10px;
  }
  video{
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%) scaleX(-1);
  }
}