.PhotoGrid{
  height: 220px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 8px;
  margin-bottom: 30px;
  .PhotoBox:first-child{
    grid-row: span 2;
  }
  .shoot{
    width: 50px;
    height: 50px;
    z-index: 1;
    position: absolute;
    color: #fff;
    border-radius: 50%;
    background-color: #1b7a9e;
    left: 50%;
    margin-left: -25px;
    bottom: -20px;
    line-height: .6;
    box-shadow: 0 2px 23px 0 rgba(27,122,158,.5);
    cursor: pointer;
    background-image: url(../../assets/images/signup/camera.svg);
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center;
    transition: all .3s ease;
    &.ready{
      background-color: rgba(0,0,0,.4);
      filter: invert(.9);
      bottom: 10px;
    }
  }
  .import{
    width: 35px;
    height: 35px;
    z-index: 1;
    position: absolute;
    color: #fff;
    border-radius: 50%;
    background-color: rgba($color: #eee, $alpha: .8);
    right: 10px;
    margin-left: -25px;
    top: 10px;
    line-height: .6;
    box-shadow: 0 0 0 1px rgba($color: #fff, $alpha: 1);
    cursor: pointer;
    background-image: url(../../assets/images/signup/folder.svg);
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: 57% 50%;
    opacity: .5;
    transform: scale(0);
    transition: opacity .2s ease, transform .3s ease;
    &.active{
      transform: scale(1)
    }
  }
  .PhotoBox:hover{
    .import, .close-shoot{
      opacity: .9;
    }
  }
  .close-shoot{
    width: 30px;
    height: 30px;
    z-index: 1;
    position: absolute;
    color: #fff;
    border-radius: 50%;
    background-color: rgba($color: #eee, $alpha: .8);
    right: 10px;
    margin-left: 35px;
    left: 50%;
    line-height: .6;
    bottom: 20px;
    box-shadow: 0 0 0 1px rgba($color: #fff, $alpha: 1);
    cursor: pointer;
    background-image: url(../../assets/images/close.svg);
    background-size: 85%;
    background-repeat: no-repeat;
    background-position: center;
    opacity: .5;
    transform: scale(0);
    transition: opacity .2s ease, transform .3s ease;
    background-color: rgba(0,0,0,.9);
    filter: invert(.9);
    &.active{
      transform: scale(1);
    }
  }
}