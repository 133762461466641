.LesCheries {
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .image {
    width: 150px;
    height: 150px;
    border-radius: 0.5em;
    background-position: center;
    background-image: url(../../assets/images/home/photo.png);
    background-size: cover;
  }
  .name {
    position: relative;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;

    font-size: 1rem;
    color: #000;
    font-weight: 500;
    margin-bottom: 0;
  }

  &.connected::after {
    position: absolute;
    top: 8px;
    left: 8px;
    content: "";
    width: 11px;
    height: 11px;
    background: #72da00;
    border-radius: 100%;
    display: block;
  }
  &.premium::before {
    position: absolute;
    top: 8px;
    right: 8px;
    content: "";
    width: 18px;
    height: 18px;
    background: #3ccca7;
    border-radius: 100%;
    display: block;
    background-image: url(../../assets/images/home/picto.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%;
  }
}
