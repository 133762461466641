.CherieLink{
  font-weight: 500;
  color: #1b7a9e;
  font-size: 1rem;
  margin-bottom: 1rem;
  span{
    text-decoration: none;
    background-image: url(../../assets/images/defi/defi.svg);
    background-position: 100% 50%;
    background-size: auto 200%;
    background-repeat: no-repeat;
    padding-right: 2.5em;

  }
  &:hover{
    text-decoration: underline;
    cursor: pointer;
  }
}