$fonts:(
    "ttr":      ('Roboto', sans-serif),
    "mont":  ('Montserrat', sans-serif),
    "cond": ('Roboto Condensed', sans-serif)
);

// path for fontawesome
$fa-font-path:        "../fonts";

$blueBlack : #002f3f;
$bluePrimary : #1b7a9e;
$blueNight: #00375b;
$brownGrey: #8c8c8c;

$theme-colors: (
    "primary":      #1b7a9e,
    "secondary":    #453d64
);