.CharteField{
  display: flex;
  align-items: flex-start;
  >img{
    width: 45px;
    min-width: 45px;
    margin-right: 1rem;
  }
  .cf-texts{
    margin-bottom: 1.5em;
    .cf-title{
      font-size: 24px;
      color: #00375b;
      font-weight: 700;
      line-height: 1.2;
    }
    .cf-detail{
      font-weight: 500;
      text-shadow: none;
      font-size: 16px;
      line-height: normal;
      color: #8c8c8c;
    }
  }
}