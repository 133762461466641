.EventTicket{
  filter: drop-shadow(0 10px 15px #dbdbdb) drop-shadow(0 0 5px #dbdbdb);
  display: flex;
  align-items: stretch;
  .et-box{
    flex: 1;
    padding: 2em 10%;
    border-radius: 15px;
    margin: auto;
    background: #fff;
    mask-border: url(./a.svg);
    display: flex;
    flex-direction: column;
    align-items: center;
    .et-code-title{
      font-size: 20px;
      color: #00375b;
      text-shadow: none;
      font-weight: 900;
      text-align: center;
    }
    .et-qr{
      width: 162px;
      height: 162px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: flex;
      justify-content: center;
      align-items: center;
      >svg{
        width: 80%;
        height: 80%
      }
    }
    .et-detail{
      font-size: 16px;
      margin-bottom: 24px;
      line-height: normal;
      letter-spacing: normal;
      color: #8c8c8c;
      font-weight: 500;
      margin: 16px 0;
    }
    .et-options{
      align-self: stretch;
      display: grid;
      gap: 2em;
      grid-template-columns: 1fr 1fr;
    }
    .et-separator{
      margin: 0 -12px;
      height: 2px;
      background: rgba(220,220,220,.5);
      align-self: stretch;
      margin-top: 2em;
    }
  }
}