.Bubble{
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  .b-logo{
    width: 80px;
    height: 80px;
    border-radius: 11.1px;
    box-shadow: 0 10px 17px 0 rgba(0,0,0,.15);
    background-color: #fff;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .b-texts{
    margin-left: 10px;
    .b-title{
      font-size: 20px;
      color: #00375b;
      margin-bottom: 8px;
      font-weight: 700;
      line-height: normal;
    }
    .b-detail{
      font-size: 16px;
      color: #1b7a9e;
      margin-bottom: 0;
      font-weight: 500;
    }
  }
}