.CherieInput{
  display: flex;
  input{
    flex: 1;
    border: none;
    border-bottom: 2px solid #bebebe;
    border-radius: 0;
    color: #bebebe;
    background-color: transparent;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    outline: none;
    &:not(.simple){
      margin-bottom: 1.2em;
    }
    &::placeholder{
      color: #bebebe;
      font-weight: 400;
    }
    &[type="date"] {
      text-transform: uppercase;
      text-align: center;
      border-bottom: none;
      border-bottom: 2px solid #bebebe;
      letter-spacing: 5px;
      margin-left: 15px;
      border-bottom: none;
      font-family: inherit;
      &::-webkit-datetime-edit-day-field {
        border-bottom: 2px solid #bebebe;
        letter-spacing: 5px;
        padding-left: 5px;
        margin-right: 15px;
      }
      &::-webkit-datetime-edit-month-field {
        border-bottom: 2px solid #bebebe;
        letter-spacing: 5px;
        padding-left: 5px;
        margin: 0 15px;
      }

      &::-webkit-datetime-edit-year-field {
        border-bottom: 2px solid #bebebe;
        letter-spacing: 5px;
        padding-left: 5px;
        margin-left: 15px;
      }
    }
  }
}