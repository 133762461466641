@import "../../assets/scss/variables.scss";

.SignUp {
  background-image: url("../../assets/images/bg.jpg");
  background-size: cover;
  height: 100vh;
  background-attachment: fixed;
  .sgp-title{
    color: #00375b;
    font-size: 2.5rem;
    font-weight: 900;
    margin-bottom: 1.5rem;
    line-height: normal;
    &.sub{
      font-size: 1.25rem;
      font-weight: 800;
    }
    &.text{
      font-size: 16px;
      font-weight: 500;
    }
    &.reducted{
      margin-bottom: 8px;
    }
    &.spaced{
      margin-top: 2rem;
    }
  }
  .sgp-minitext{
    color: #00375b;
    font-weight: 500;
    font-size: 12px;
    margin-top: 1rem;
  }
  .sgp-text-parent{
    display: flex;
    margin-bottom: 1em;
    textarea{
      padding: .375rem .75rem;
      flex: 1;
      height: 189px;
      border-radius: 5px;
      box-shadow: inset 0 1px 19px 0 rgba(50, 50, 50, 0.01);
      border: solid 1px #00375b;
      background-color: #ffffff;
      &::placeholder {
        color: #e9e9e9;
        font-size: 0.75rem;
      }
    }
  }
  .checkbox {
    display: flex;
    cursor: pointer;
    span{
      font-size: 0.75rem;
      color: #00375b;
      font-weight: 500;
      user-select: none;
    }
    input {
      display: none;
    }
    .checkmark {
      position: relative;
      height: 24px;
      width: 24px;
      min-width: 24px;
      margin-right: .5em;
      border: 1px solid #141414;
      border-radius: 4px;
      box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.25);
      &::after {
        content: "";
        position: absolute;
        display: none;
        left: 8px;
        top: 0px;
        width: 6px;
        height: 16px;
        border: solid #141414;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
    input:checked ~ .checkmark:after{
      display: block;
    }
  }
}
